import Vue from 'vue'
import App from './App.vue'
import Overlay from './Overlay.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'

import moment from 'moment-timezone'
moment.tz.setDefault("Europe/Paris");

Vue.use(Meta)

Vue.config.productionTip = false

let app = App

if (window.location.href.includes('overlay')) {
  app = Overlay
}

new Vue({
  router,
  store,
  render: h => h(app)
}).$mount('#app')
