<template>
  <div class="home">
    <div class="page-content">

      <div class="info-container">
        <div class="info-content">
          Du 1er au 30 decembre, c'est le <b>Special Christmas</b> plein de cadeaux et de lot a gagner sur la chaine !
        </div>

        <div class="info-content fake">
          Du 1er au 30 decembre, c'est le <b>Special Christmas</b> plein de cadeaux et de lot a gagner sur la chaine !
        </div>

        <i class="fad fa-info"></i>
      </div>

      <div class="header">

        <div class="display_name">{{ user.display_name }}</div>

        <div class="is_sub" v-if="userData.isSubscriber">
          <i class="fas fa-star"></i>
          Abonné(e) Tier 1
        </div>

      </div>

      <div class="sub-header">

        <i class="far fa-chevron-double-right"></i>

        Follow depuis le 24 Octobre

      </div>

      <div class="dashboard-container">

        <div class="line-container">

          <div class="card-container double">
            <div class="card-header">
              Evolutions
            </div>

            <div class="card-content">
              En attente de data
            </div>
          </div>

          <div class="card-container">
            <div class="card-header">
              Transactions
            </div>

            <div class="card-transaction">

              <div class="transaction-list" v-if="transactions.length">

                <div class="transation-item" v-for="transaction in transactions" :key="transaction.id">

                  <div class="info">
                    <span class="transaction-type">{{ transaction.type }}</span>
                    <span class="created-at"> {{ moment(transaction.createdAt.seconds * 1000).calendar() }} </span>
                  </div>

                  <div class="amount-container">
                    <span class="amount">+{{ transaction.amount }}</span> Boules
                  </div>
                </div>


              </div>

              <div v-else>
                Aucune transactions
              </div>

            </div>
          </div>
        </div>


        <div class="line-container">

          <div class="card-container" @click="$router.push({name: 'achievement'})">
            <div class="card-header">
              Achievement 
              <span class="details">
                <span class="bold">{{ claimedAchievement.length }}</span> / <span class="bold">{{ achievements.length }}</span>
              </span>
            </div>

            <div class="card-achievement">
              <div class="success-container" v-for="succes in currentAchievement.splice(0, 4)" :key="succes.id">

              <div :class="`icon-container disable level${succes.level}`" :style="succes.color">
                <div v-if="succes.class" :class="succes.class" class="class-icon"></div>
                <i v-if="succes.icon" :class="succes.icon"></i>
              </div>

              <div class="success-content">

                <div class="title">{{ succes.hidden ? 'Achievement masquer' : succes.title }}</div>

                <div class="description">
                  {{  succes.hidden ? 'Vous devez trouvez comment débloquer cet achievement' : succes.description }}
                </div>
                
              </div>

              <div class="success-objectif" v-if="succes.require">
                <div class="actual">{{ valueFormater(getStat(succes.require.field), succes.require.type) }}</div>
                <span>/</span>
                <div class="objectif">{{ valueFormater(succes.require.value, succes.require.type) }}</div>          
              </div>

              <div class="success-objectif" v-else>
                <div class="objectif"></div>          
              </div>

              <div class="success-progress" v-if="succes.require" :style="{ ...succes.color, width: percentage(succes.require) + '%' }" />

            </div>

            </div>
          </div>

          <div class="card-container">
            <div class="card-header">
              En attentes
            </div>

            <div class="card-content">
              Aucun jeu en attente
            </div>
          </div>

          <div class="shop-card card-container" @click="$router.push({name: 'shop'})">
            <div class="card-header">
              La boutique
            </div>

            <i class="fad fa-shopping-cart fa-flip-horizontal background-icon" flip></i>

          </div>

        </div>


      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

moment.locale('fr');  

export default {
  name: 'Dashboard',

  metaInfo () {
    return {
      title: `Dashboard - Alxios`
    }
  },

  async created() {
    const users = await db.collection('users').where('userId', '==', this.user.id).get()

    const statRef = users.docs[0].ref.collection('statistics-all').doc('all')
    const achievementRef = users.docs[0].ref.collection('achievements')
    const transactionsRef = users.docs[0].ref.collection('transactions').orderBy('createdAt', 'desc')

    this.$bind('stats', statRef)
    this.$bind('achievement', achievementRef)
    this.$bind('transactions', transactionsRef)
  },

  firestore() {
    return {
      users: db.collection('users').where('userId', '==', this.user.id),
      achievements: db.collection('achievements')
    }
  },

  data () {
    return {
      rewards: [],
      games: [],
      users: [],

      transactions: [],

      achievements: [],

      stats: {},
      achievement: [],

      moment
    }
  },

  methods: {

    getAchivementData ({ key }) {
      const achievement = this.achievement.find(x => x.key == key)
      return moment(achievement.createdAt.seconds * 1000).calendar('DD/MM/YYYY')
    },

    getStat(key) {
      const value = this.stats[key]
      if (!value) return 0
      return Math.round(Number(value))
    },

    valueFormater (value, type, sufix = true) {
      if (type == 'hour') {
        return Number(value / 60).toFixed(2).replace('.', ',').replace(',00', '') + ' h'
      } else {
        if (value >= 1_000) return Number(value / 1_000).toFixed(2).replace('.', ',').replace(',00', '') + ' k'
        return value
      }
    },

    percentage(require) {
      if (!require) return 0

      const { field, value } = require
      const current = this.getStat(field)
      const percentage = (current / value) * 100
      return percentage > 100 ? 100 : percentage
    }

  },

  computed: {
    ...mapState(['user']),

    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    },

    claimedAchievement () {
      return this.achievements.filter(x => {
        if (x.require) {
          return this.getStat(x.require.field) >= x.require.value
        }
        return this.achievement.find(y => y.key == x.key) ? true : false
      }).sort((a, b ) => a.publicId - b.publicId)
    },

    currentAchievement () {
      return this.achievements.filter(x => {
        return this.claimedAchievement.find(y => y.publicId == x.publicId) ? false : true
      }).sort((a, b ) => this.percentage(b.require) - this.percentage(a.require))
    }
  }

}

</script>

<style lang="scss" scoped>
.transaction-list {
  width: 100%;
}
.transation-item {
  display: flex;
  flex-direction: row;

  width: 100%;

  align-items: center;

  margin: 12px 0;

  .amount-container {
    align-items: flex-end;
    width: 100%;
    text-align: right;
    color: rgb(82, 193, 119);
    white-space: nowrap;
    font-size: 16px;
  }

  .info {
    font-size: 16px;
    .created-at {
      white-space: nowrap;
      margin-top: 4px;
      line-height: 17px;
    }

    .transaction-type {
      letter-spacing: .6px;
      font-weight: 700;
      color: white;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.success-container {
  position: relative;
  background: #171717;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin: 2px 0;
  overflow: hidden;

  min-height: 64px;

  transition: .3s ease all;

  cursor: pointer;

  .success-progress {
    position: absolute;

    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;

    background: #7283ac;
  }

  &:hover {
    background: lighten($color: #171717, $amount: 5)
  }

  .success-content {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .success-objectif {
    display: flex;
    align-items: baseline;
    margin-left: 24px;

    margin-right: 12px;

    .actual {
      white-space: nowrap;
      color: white;
      font-weight: 700;
    }

    span {
      margin: 0 4px;
    }

    .objectif {
      white-space: nowrap;
      font-size: 13px;
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
    border-radius: 5px;
    margin-right: 16px;

    position: relative;

    &.level1::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'I';
    }

    &.level2::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'II';
    }

    &.level3::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'III';
    }

    &.level4::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'IV';
    }

    &.level5::after {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      /* line-height: 37px; */
      top: -6px;
      right: -12px;
      height: 21px;
      width: 21px;
      font-size: 12px;
      letter-spacing: 0.8px;
      background: #542f8b;
      border: 2px solid #171717;
      color: white;
      font-weight: 700;
      border-radius: 50%;
      content: 'V';
    }

    &.disable {
      opacity: 0.3;
    }

    svg {
      color: white;
      width: 28px;
      height: 28px;
    }
  }

  .title {
    color: white;
    letter-spacing: .6px;
    font-weight: 700;
  }

  .description {
    white-space: nowrap;
    margin-top: 4px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//

.line-container {
  display: flex;

  .card-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    padding-bottom: 32px;
  }

  .card-transaction {
    display: flex;

    overflow: scroll;
    height: 100%;
    margin-top: 14px;
    flex-direction: column;
  }

  .card-achievement {
    display: flex;

    overflow: scroll;

    height: 100%;

    margin-top: 14px;
    
    flex-direction: column;
  }

  .card-container {
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 340px;
    background: #171717;
    margin: 10px;
    border-radius: 10px;

    padding: 18px 24px;

    cursor: pointer;

    transition: 0.5s ease all;

    box-shadow: transparent 0px 0px 0px 3px;

    .background-icon {
      position: absolute;
      top: 20%;
      right: -12%;
      height: 100%;
      width: 100%;
    }


    &:hover {
      box-shadow: #9999995c 0px 0px 0px 3px;
    }

    .card-header {
      color: white;

      .details {
        font-size: 14px;
        margin-left: 12px;
        opacity: 0.54;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.shop-card {
  background-color: #8BC6EC !important;
  background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%) !important;

  svg {
    color: #2c6094;
    position: absolute;
    top: 14% !important;
    right: -20% !important;
    height: 100%;
    width: 100%;
  }
}

.sub-header {
  margin: 18px 0;

  color: #999999;

  font-weight: 400;

  svg {
    margin-right: 8px;
  }
}

.header {
  display: flex;
  flex-direction: row;

  .display_name {
    font-size: 32px;
    color: white;
    font-weight: 600;
    margin-right: 20px;
  }

  .is_sub {
    background: #171717;
    border-radius: 10px;
    color: #e6ff18;
    padding: 10px 12px;
    padding-right: 16px;
    font-size: 15px; 

    margin-bottom: -6px;

    svg {
      margin-right: 5px;
    }
  }

  
}

.info-container {
  position: relative;

  padding: 18px 24px;
  border-radius: 10px;
  color: white;
  margin-bottom: 42px;
  font-size: 15px;
  line-height: 18px;

  overflow: hidden;

  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

  box-shadow: #00000033 0px 2px 8px 0px;

  &::after {
    color: #fbad7d;
    position: absolute;
    top: -2px;
    font-weight: 700;
    letter-spacing: 2px;
    left: 24px;
    text-transform: uppercase;
    background: #1e1e1e;
    border: 2px solid #fab07a;
    padding: 4px 8px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    content: 'Information';
  }

  svg {
    position: absolute;
    top: 0;
    left: 49.5%;
    height: 100%;
    width: 100%;
    color: #ffa93ff2;
    z-index: 0;
  }

  .info-content {
    margin-top: 22px;

    z-index: 1;
    opacity: 0;

    line-height: 20px;

    text-shadow: 1px 1px 6px #00000059;

    &.fake {
      opacity: 1;
      position: absolute;
      top: 18px;
      margin-right: 24px;
    }
  }
}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.page-content {
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 32px;
}

@media screen and (max-width: 900px) {

  .home {
    max-height: calc(100vh) !important;
    padding-bottom: 142px;
  }

}

@media screen and (max-width: 1400px) {
  .line-container {
    flex-direction: column;
  }

  .card-container {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1225px) {

}

@media screen and (max-width: 900px) {

}


@media screen and (max-width: 610px) {

}

</style>

