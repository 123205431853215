<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
export default {
  name: 'App',

  metaInfo () {
    return {
      title: `Overlay - Alxios`
    }
  }

}

</script>

<style scoped>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html, body {
	height: 100%;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
  border-collapse: collapse;
	border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
	box-sizing: border-box;
}

</style>

<style lang="scss" scoped>

body {
  background: url('/background/bg.png');
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700');

@font-face {
  font-family: 'Whitney';
  font-weight: 300;
  src: url('./assets/font/whitneylight.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 400;
  src: url('./assets/font/whitneymedium.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 600;
  src: url('./assets/font/whitneysemibold.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 700;
  src: url('./assets/font/whitneybold.otf');
}

</style>
