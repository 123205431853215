import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/Shop.vue')
  },

  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/planning.vue')
  },

  {
    path: '/collection',
    name: 'collection',
    component: () => import('../views/Collection.vue')
  },

  {
    path: '/quest',
    name: 'quest',
    component: () => import('../views/Quest.vue')
  },

  {
    path: '/achievement',
    name: 'achievement',
    component: () => import('../views/achievement.vue')
  },

  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/team.vue')
  },

  {
    path: '/requests',
    name: 'request',
    component: () => import('../views/Request.vue')
  },
  {
    path: '/classement',
    name: 'classement',
    component: () => import('../views/Classement.vue')
  },
  {
    path: '/hype',
    name: 'hype',
    component: () => import('../views/hype.vue')
  },
  {
    path: '/all',
    name: 'all',
    component: () => import('../views/RequestAll.vue')
  },

  // overlay

  {
    path: '/overlay/robot',
    name: 'overlay-robot',
    component: () => import('../overlays/Robot.vue')
  },

  {
    path: '*',
    redirect: { name: 'Dashboard' }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
