<template>
  <div class="home">

    <div class="page-container">

      <div class="login-with-twitch" @click="login">
        <i class="fab fa-twitch"></i>Connection Twitch
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'Home',

  async beforeCreate () {
    const access_token = this.$route.hash.substring(14, 44) || localStorage.getItem('access_token')

    if (access_token) {
      localStorage.setItem('access_token', access_token)
      await this.$store.dispatch('fetchUser')

      this.$router.push({ name: 'Dashboard' })
    }

  },

  methods: {
    login () {
      window.location.replace(
        `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=vux0k8gnif6lfzqvp9ynt89vrfxdd5&redirect_uri=https://alxios.com&scope=user:read:email`
      )
    }
  }
}

</script>

<style lang="scss" scoped>

@media screen and (max-width: 900px) {
  .title {
    font-size: 42px !important;
  }

  .page-container {
    padding: 0 24px;
  }
}

.sub-title {
  color: rgb(189, 194, 196);
  font-size: 16px;

  font-weight: 400;

  margin-bottom: 36px;
}

.home {
  min-height: 100vh;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 800px;
  width: 100%;
  min-height: 100vh;

  margin: 0 auto;
}

.login-with-twitch {
  position: relative;

  background-color: #9146ff;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 8px!important;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: white;

  max-width: 400px;
  width: 100%;

  cursor: pointer;

  svg {
    position: absolute;
    left: 12px;
    height: 21px;
    width: 21px !important;
  }

  transition: .3s ease all;

  &:hover {
    background: lighten($color: #9146ff, $amount: 5);
  }
}


@keyframes blink {
  20%,
  24%,
  55% {
      color: #ccced0;
      text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
      text-shadow:
          0 0 6px rgba(202,228,225,0.98),
          0 0 30px rgba(202,228,225,0.42),
          0 0 12px rgba(30,132,242,0.58),
          0 0 22px rgba(30,132,242,0.84),
          0 0 38px rgba(30,132,242,0.88),
          0 0 60px rgba(30,132,242,1);
      color: #e6f2ff;
  }
}

.header .title {
  font-family: "Sacramento";
  text-shadow: none;
  color: transparent;
  font-size: 64px;
  font-weight: bold;

  white-space: nowrap;

  margin-bottom: 148px;

  animation: blink 35s infinite;

  text-shadow:
      0 0 6px rgba(202,228,225,0.98),
      0 0 30px rgba(202,228,225,0.42),
      0 0 12px rgba(30,132,242,0.58),
      0 0 22px rgba(30,132,242,0.84),
      0 0 38px rgba(30,132,242,0.88),
      0 0 60px rgba(30,132,242,1);
  color: #e6f2ff;
}

</style>

