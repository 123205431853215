<template>
  <div id="app">

    <div class="header">
      <div class="header-content">
        <div class="menu-container" @click="showMenu = !showMenu">
          <div class="icon-container">
            <i class="fad fa-bars"></i>
          </div>
        </div>

      </div>
    </div>

    <div class="live-stream-container">
      <iframe
        src="https://player.twitch.tv/?channel=alxios&parent=alxios.com"
        height="100%"
        width="100%"
        frameborder="0"
        scrolling="false"
        allowfullscreen="true">
      </iframe>
    </div>

    <div class="app-container">

      <div class="background" :class="{ active: showMenu }" @click="showMenu = !showMenu"/>

      <div class="side-panel-container" :class="{ opened: showMenu }" v-if="user">

        <div class="bg-1"></div>
        <div class="bg-2"></div>
        
        <div class="navigation">

          <a class="brand-header" href="http://twitch.tv/alxios" target="blank">

            <i class="fab fa-twitch"></i>
            <div class="separator">|</div>
            <div class="channel-name">
              Alxios
              <div class="channel-link">
                www.twitch.tv/alxios
              </div>
            </div>

          </a>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/dashboard">
            <i class="fad fa-chart-pie-alt"></i>
            <div class="title">Dashboard</div>
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/calendar">
            <i class="fad fa-calendar-alt"></i>
            <div class="title">Calendrier</div>

            <!-- <div class="selected"></div> -->
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/shop">
            <i class="fad fa-shopping-cart"></i>
            <div class="title">Boutique</div>

            <div class="notif-container">Nouveau</div>
            <!-- <div class="selected"></div> -->
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/requests">
            <i class="fad fa-gamepad-alt"></i>
            <div class="title">En attentes</div>

            <!-- <div class="notif-container">2 disp.</div> -->
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/achievement">
            <i class="fad fa-trophy"></i>
            <div class="title">Succes</div>

            <div class="notif-container">Nouveau</div>
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/quest">
            <i class="fad fa-scroll-old"></i>
            <div class="title">Quetes</div>

            <div class="notif-container">Nouveau</div>
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem disable" to="/achievement">
            <i class="fad fa-hat-wizard"></i>
            <div class="title">Secret</div>

            <div class="notif-container">Bientôt</div>
          </router-link>

          <router-link @click.native="showMenu = false" class="navigation-elem" to="/classement">
            <i class="fad fa-chart-bar"></i>
            <div class="title">Classement</div>

            <!-- <div class="notif-container">Bientôt</div> -->
          </router-link>

          <!-- <router-link class="navigation-elem" to="/settings">
            <i class="fad fa-cogs"></i>
            <div class="title">Paramètres</div>
          </router-link> -->


        </div>

        
          <div class="bottom-block">

            
          <!-- <div class="succes-container">

            
            <div class="succes-content">

              <div class="title">Achievements</div>
              <div class="description">
                Je suis un texte peu utile, mais tout de meme assez long pour l'example.
              </div>

              <div class="progress-bar">
                <span class="bar"></span>
              </div>
            </div>

            <div class="succes-content absolute">

              <div class="title">Achievements</div>
              <div class="description">
                Je suis un texte peu utile, mais tout de meme assez long pour l'example.
              </div>

              <div class="progress-bar">
                <span class="bar"></span>
              </div>
            </div>

            <i class="fad fa-trophy"></i>

          </div> -->

          <div class="user-container"  v-if="user">

            <div class="profil-image" :style="{
            'background-image': `url(${user.profile_image_url})`
            }"></div>

            <div class="user-info" v-if="userData">
              <div>{{ user.display_name }}</div>
              <div>
              {{ new Intl.NumberFormat().format(Math.round(userData.boule2noel)) }} <b>Boules de Noel</b>
              </div>
              <div>
              {{ new Intl.NumberFormat().format(Math.round(userData.points)) }} <b>Asticots</b> 
              </div>
            </div>

          </div>


          </div>



      </div>

      <transition name="fade" :key="$route.name" mode="out-in">
        <router-view v-if="user || $route.name == 'Home'" class="page-container-container" />
      </transition>

    </div>


 

  </div>
</template>


<script>
import { db } from './firebase'
import { mapState } from 'vuex'

export default {
  name: 'App',

  metaInfo () {
    return {
      title: `Dashboard - Alxios`
    }
  },

  firestore() {
    if (!this.user) return

    return {
      users: db.collection('users').where('userId', '==', this.user.id)
    }
  },

  watch: {
    user ({ id }) {
      this.$bind('users', db.collection('users').where('userId', '==', this.user.id))
    } 
  },

  data () {
    return {
      users: [],
      showMenu: false,
    }
  },

  computed: {
    ...mapState(['user']),

    userData() {
      return this.users[0]
    }
  },

  async created() {
    try {
      await this.$store.dispatch('fetchUser')
    } catch (e) {
      if (this.$route.name === 'hype') return

      this.$router.push('/')
    }
  },

}

</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, captBBBEion, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html, body {
	height: 100%;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
  border-collapse: collapse;
	border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
	box-sizing: border-box;
}

</style>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700');

@font-face {
  font-family: 'Whitney';
  font-weight: 300;
  src: url('./assets/font/whitneylight.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 400;
  src: url('./assets/font/whitneymedium.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 600;
  src: url('./assets/font/whitneysemibold.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 700;
  src: url('./assets/font/whitneybold.otf');
}

.live-stream-container {
  left: -500px;

  position: absolute;
    right: 24px;
    bottom: 24px;
    width: 100%;
    max-width: 360px;
    height: calc(360px/(16/9));
    border-radius: 12px;

    background: black;
    overflow: hidden;
    box-shadow:
      0 0 0 1px rgba(4,4,5,0.15),
      0 8px 16px rgba(0,0,0,0.24);

    transition: 350ms ease all;
}

.live-stream-container:hover {

  max-width: 880px;
  height: calc(880px/(16/9));

//   box-shadow:
//     rgba(58, 151, 212, 0.34) 0px 0px 0px 4px,
//     0 0 0 1px rgba(4,4,5,0.15),
//     0 8px 16px rgba(0,0,0,0.24);
}

.user-container {

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 32px 0;

  // border-top: 2px solid #1e1e1e;

  margin-top: 42px;

  .profil-image {
    height: 48px;
    width: 48px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;

    margin-right: 18px;
  }
}

body {
  font-family: 'Whitney';
  background: #1E1E1E;

  background-image: linear-gradient(349deg, #181717 0%, #242424 100%);

  max-height: 100vh !important;
  overflow: hidden !important;

  color: #B9BBBE;
}

$title-color: #FFFFFF;

</style>

<style lang="scss" scoped>

#app {
  max-height: 100vh !important;
  overflow: hidden !important;
}

body {
  max-height: 100vh !important;
  overflow: hidden !important;
}

.icon-container {
  height: 48px;
  width: 48px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
  }
}

.notif-container {
  position: absolute;
  right: 0;
  background: #F45C3B;
  font-size: 14px;
  height: 24px;
  display: flex;
  padding: 0 13px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  color: white;
}

.live-stream-container {
  z-index: 1000;
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 100%;
  max-width: 320px;
  height: calc(320px/(16/9));
  border-radius: 12px;

  background: black;
  overflow: hidden;
  box-shadow:
    0 0 0 1px rgba(4,4,5,0.15),
    0 8px 16px rgba(0,0,0,0.24);

  transition: 350ms ease all;
}

.live-stream-container:hover {
  max-width: 880px;
  height: calc(880px/(16/9));
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.page-container-container {
  width: 100%;

  padding: 0 24px;
}

.brand-header {
  display: flex;
  flex-direction: row;

  align-items: center;

  font-size: 32px;

  color: white;

  padding: 32px 0;

  font-weight: 600;

  border-bottom: 2px solid #1e1e1e;

  cursor: pointer;

  &:hover {

    .separator {
      transform: translateX(124px) rotate(18deg);
    }

    .channel-name {
      transform: translateX(-18px);
    }

    .channel-link {
      color: #d2b3ffab !important;
    }

  }

  .channel-name {
    margin-left: 10px;
    margin-top: -3px;
    font-size: 26px;

    transition: .3s ease all;

    cursor: pointer;

    .channel-link {
      font-size: 11px;
      font-weight: 300;
      line-height: 15px;
      color: #747474;
      margin-left: 0px;
      font-family: 'Open Sans';

      transition: .3s ease all;
    }
  }

  .separator {
    margin-top: -8px;
    transform: rotate(18deg);
    color: #b9bbbe;

    transition: .3s ease all;
  }

  svg {
    color: #9147FF;
    margin-right: 16px;
  }
}

.succes-container {

  cursor: pointer;

  transition: .3s ease all;

  &:hover {
    transform: scale(1.03);
  }

  svg {
    position: absolute;
    top: 0;
    right: -37%;
    height: 100%;
    width: 100%;
    color: #9fd8bb;
    z-index: 0;
  }

  position: relative;
  overflow: hidden;

  background-color: #6aca99;

  border-radius: 10px;

  padding: 18px 24px;

  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  .succes-content.absolute {
    position: absolute;
    top: 18px;
    margin-right: 24px;

    opacity: 1;

    z-index: 1;
  }

  .succes-content {
    opacity: 0;
  }

  .progress-bar {
    position: relative;

    width: 100%;
    height: 5px;
    background: #fefbff;
    margin-top: 16px;
    border-radius: 5px;

    span {
      position: absolute;

      top: 0;
      left: 0;

      background: red;
      width: 50%;
      height: 100%;

      border-radius: 5px;
    }
  }

  .title {
    color: white;
    margin-bottom: 8px;
  }

  .description {
    color: #626364;
    font-size: 14px;
    line-height: 17px;

  }
}

.navigation-elem {
  position: relative;
  height: 24px;
  align-items: center;

  color: #747474;

  display: flex;
  flex-direction: row;

  font-size: 18px;

  margin: 16px 0;
  padding: 8px 12px;

  transition: .3s ease all;
  box-sizing: content-box;

  &.router-link-active {
    color: white;
  }

  &:hover {
    background: #1e1e1e;
    border-radius: 3px;
  }

  &.disable {
    color: #333333;
    cursor: default;

    .notif-container {
      opacity: 0.4;
    }

    &:hover {
      background: none;
    }
  }

  .selected {
    position: absolute;
    height: 24px;
    right: -19px;
    width: 3px;
    background: white;
    border-radius: 3px;
  }

  svg {
    margin-right: 18px;
    width: 21px;
  }
}

.app-container {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100vh;
}

.side-panel-container {

  .bg-1 {
        position: absolute;
    background: #6893c638;
    right: -33px;
    bottom: -165px;
    width: 250px;
    height: 429px;
    z-index: -14;
    transform: rotate(234deg);
  }

  .bg-2 {
    position: absolute;
    background: #2c60940f;
    right: 32px;
    bottom: -184px;
    width: 313px;
    height: 542px;
    z-index: -14;
    transform: rotate(321deg);
  }

    backdrop-filter: blur(4px);
    z-index: 100000;
    bottom: 0;
    top: 0;
    transition: 225ms ease all;
    overflow: hidden;
    transition: 250ms ease all;
    box-shadow: 1px 2px 32px 0 rgba(0, 0, 0, 0.46);
    // border: 1px solid rgb(43, 43, 43);

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  // background: #171717;
  width: 284px;
  min-width: 284px;

  padding: 0 24px;

  background: #171717fa;

  // box-shadow: #00000033 0px 2px 8px 0px;

  // border-top-right-radius: 14px;
  // border-bottom-right-radius: 14px;

  overflow: hidden;
}

.menu-container {
  display: flex;
  flex-direction: row;

  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  .elem {
    padding: 2px 8px;
    margin: 0 10px;

    cursor: pointer;

    border-radius: 3px;

    transition: 0.3s ease all;


    &.router-link-exact-active {
      color: white;
      background: #3E4249;
    }

    &:hover {
      background: #3E4249;
    }
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
      color: #ccced0;
      text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
      text-shadow:
          0 0 6px rgba(202,228,225,0.98),
          0 0 30px rgba(202,228,225,0.42),
          0 0 12px rgba(30,132,242,0.58),
          0 0 22px rgba(30,132,242,0.84),
          0 0 38px rgba(30,132,242,0.88),
          0 0 60px rgba(30,132,242,1);
      color: #e6f2ff;
  }
}

.neon .title {
  font-family: "Sacramento";
  text-shadow: none;
  color: transparent;
  font-size: 28px;
  font-weight: bold;

  margin-top: 4px;
  margin-right: 48px;

  white-space: nowrap;
  animation: blink 35s infinite;

  text-shadow:
      0 0 6px rgba(202,228,225,0.98),
      0 0 30px rgba(202,228,225,0.42),
      0 0 12px rgba(30,132,242,0.58),
      0 0 22px rgba(30,132,242,0.84),
      0 0 38px rgba(30,132,242,0.88),
      0 0 60px rgba(30,132,242,1);
  color: #e6f2ff;
}

.header {
  display: none;
}

.user-info div {
  margin: 2px 0;
}

@media screen and (max-width: 780px) {

  .live-stream-container {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    transform: translateX(5000px);
  }

  .page-container-container {
    padding: 0 12px;
  }

  .header {
    display: block;
  position: absolute;
  top: 0;

  height: 64px;
  background: #202225;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);

  width: 100vw;
  z-index: 100;

  .header-content {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: row;

    max-width: 1500px;
    width: 100%;
    height: 100%;

    padding: 0 12px;

    margin: 0 auto;

    .info-container {
      display: flex;
      align-items: center;
      flex-direction: row;

      position: absolute;
      right: 12px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 12px;

      div {
        font-size: 14px;
        line-height: 18px;;
      }
    }
  }

  .profil-image {
    height: 48px;
    width: 48px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
  }
}


  .background {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 101;

    &.active {
      pointer-events: unset;
      background: #0e000069;
    }
  }

  .side-panel-container {
    position: absolute;
    top: 0;
    left: -287px;
    height: 100vh;

    z-index: 1000;

    transition: .3s ease all;

    &.opened {
      left: 0;
    }
  }

  .app-container {
    max-height: 100vh;
    padding-top: 64px;
  }

  .menu-container {
    flex-direction: column !important;

    .elem {
      margin: 8px 0 !important;
    }
  }

  .neon {
    margin-bottom: 12px;
  }

  .info-container {
    position: initial !important;
  }

}

</style>
