import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import Vue from 'vue'

import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyDxfdSCn7tbai5P65zknSxVZi9v9y_tAK0",
    authDomain: "alxios-christmas.firebaseapp.com",
    databaseURL: "https://alxios-christmas.firebaseio.com",
    projectId: "alxios-christmas",
    storageBucket: "alxios-christmas.appspot.com",
    messagingSenderId: "872840875848",
    appId: "1:872840875848:web:1e776a129d065d77bb2d7d",
    measurementId: "G-WSH9763M8K"
};
firebase.initializeApp(firebaseConfig)
firebase.analytics()

// utils
const db = firebase.firestore()
const auth = firebase.auth()

auth.signInWithEmailAndPassword('dragon93210@gmail.com', 'Novalogic2')

// export utils/refs
export {
  db,
  auth,
  firebase
}