import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios'

let defaultOptions = [
  { id: 1, key: "color", name: 'Couleurs lisibles', status: true },
  { id: 2, key: "oldMessage", name: 'Griser les anciens messages (5min)', status: false },
  { id: 3, key: "bot", name: 'Cacher les bots connus', status: true }
]

const config = JSON.parse(localStorage.getItem('settings')) || null
if (config) {
  defaultOptions = defaultOptions.map(x => {
    const field = config.find(y => y.id === x.id)
    if (!field) return
  
    x.status = field.status
    return x
  })
}

export default new Vuex.Store({

  state: {
    user: null,
    options: defaultOptions
  },

  mutations: {

    setUser (state, user) {
      state.user = user;
    },

    toggleOption (state, option) {
      const field = state.options.find(x => x.id === option.id)
      field.status = !field.status

      const config = JSON.stringify(state.options)
      localStorage.setItem('settings', config)
    }

  },

  getters: {
    hasOptionsActive: (state) => (key) => {
      const option = state.options.find(x => x.key === key)
      if (!option) return null
      return option.status
    }
  },

  actions: {

    async fetchUser ({ commit }) {
      const access_token = localStorage.getItem('access_token')
      const { data } = await axios.get('https://api.twitch.tv/helix/users', {
        headers: {
          "Authorization": `Bearer ${access_token}`,
          "Client-Id": "vux0k8gnif6lfzqvp9ynt89vrfxdd5"
        }
      })
      commit('setUser', data.data[0])
    }
  },

})
